.container {
  background-color: #f8f8f8;
  height: 100%;
}
.container .content {
  background-color: #f8f8f8;
  padding-bottom: 1rem;
}
.user_msg {
  background-color: #ffffff;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  font-family: PingFang SC;
}
.user_msg .msg {
  padding: 0.4rem 0.2rem 0.16rem 0.2rem;
}
.user_msg .msg .msg-top {
  display: flex;
  align-items: center;
  padding-bottom: 0.32rem;
}
.user_msg .msg .msg-top .msg_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1.35rem;
}
.user_msg .msg .msg-top .msg_left .img {
  width: 1.2rem;
  height: 1.2rem;
}
.user_msg .msg .msg-top .msg_left .img img {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}
.user_msg .msg .msg-top .msg_left .name {
  margin-top: 0.3rem;
  text-align: center;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.user_msg .msg .msg-top .msg_right {
  margin-left: 0.4rem;
}
.user_msg .msg .msg-top .msg_right .time {
  color: #333333;
  font-size: 0.24rem;
  font-weight: 500;
}
.user_msg .msg .msg-top .msg_right .VIP {
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}
.user_msg .msg .msg-top .msg_right .VIP .img_vip img {
  width: 0.252399rem;
  height: 0.2218rem;
}
.user_msg .msg .msg-top .msg_right .VIP .vip_number {
  margin-left: 0.1rem;
  font-size: 0.24rem;
  color: #333333;
}
.user_msg .msg .msg-top .msg_right .type {
  margin-top: 0.1rem;
  color: #5899f6;
  background-color: #e8f1fe;
  padding: 0.06rem 0 0.04rem 0.2rem;
  font-size: 0.24rem;
  font-weight: 500;
  border-radius: 0.08rem;
}
.user_msg .shuju {
  padding: 0.28rem 0 0.28rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.user_msg .shuju .list1 {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  display: flex;
  align-items: center;
  border-right: 0.02rem solid #b7d4ff;
}
.user_msg .shuju .list1 .top_left {
  text-align: center;
}
.user_msg .shuju .list1 .txt {
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.8);
}
.user_msg .shuju .list1 .txt1 {
  color: #d98181;
  font-size: 0.24rem;
  transform: scale(0.8);
}
.user_msg .shuju .list1 .left_num {
  color: #5998fc;
  font-size: 0.28rem;
}
.user_msg .shuju .list2 {
  display: flex;
  align-items: center;
}
.user_msg .shuju .list2 .top_left {
  text-align: center;
}
.user_msg .shuju .list2 .txt {
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.8);
}
.user_msg .shuju .list2 .txt1 {
  color: #d98181;
  font-size: 0.24rem;
  transform: scale(0.8);
}
.user_msg .shuju .list2 .left_num {
  color: #5998fc;
  font-size: 0.28rem;
}
.user_msg .shuju .list3 {
  padding-right: 0.2rem;
  padding-left: 0.3rem;
  display: flex;
  align-items: center;
  border-left: 0.02rem solid #b7d4ff;
}
.user_msg .shuju .list3 .top_left {
  text-align: center;
}
.user_msg .shuju .list3 .txt {
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.8);
}
.user_msg .shuju .list3 .txt1 {
  color: #d98181;
  font-size: 0.24rem;
  transform: scale(0.8);
}
.user_msg .shuju .list3 .left_num {
  color: #5998fc;
  font-size: 0.28rem;
}
.user_msg .list {
  margin: 0 0.68rem;
  padding-bottom: 0.5rem;
}
.user_msg .list .list_item {
  padding: 0.18rem 0;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.28rem;
  color: #333333;
  font-weight: 500;
}
.user_msg .list .list-right {
  text-align: right;
}
.user_msg .list .list_item:last-child {
  padding-bottom: 0.5rem;
}
.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 0;
}
.bottom .button {
  margin: 0.14rem 0.3rem 0 0.28rem;
  width: 6.9rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background-color: #5899f6;
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  border-radius: 0.12rem;
  font-weight: 500;
}
.tan {
  width: 5.6rem;
  height: 4.4rem;
  background-image: url(../../assets/imgs/tan_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 3.6rem;
}
.tan .tan_no_txt1 {
  padding: 1.4rem 0.82rem 0 0.72rem;
  display: flex;
  align-items: center;
}
.tan .tan_no_txt1 .tan_no_txt1-left {
  margin-left: 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt1 .tan_no_txt1-right {
  margin-left: 0.3rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt2 {
  margin-top: 0.3rem;
  padding: 0 0.82rem 0 0.72rem;
  display: flex;
  align-items: center;
}
.tan .tan_no_txt2 .tan_no_txt1-left {
  margin-left: 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt2 .tan_no_txt1-right {
  margin-left: 0.3rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt3 {
  padding: 0 0.82rem 0 0.72rem;
  display: flex;
  align-items: center;
}
.tan .tan_no_txt3 .tan_no_txt1-left {
  margin-left: 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt3 .tan_no_txt1-right {
  margin-left: 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_button {
  margin: 0 auto;
  width: 2.96rem;
  height: 0.58rem;
  line-height: 0.58rem;
  margin-top: 0.24rem;
  text-align: center;
  font-size: 0.28rem;
  color: #ffffff;
  background-color: #5899f6;
  border-radius: 0.16rem;
}
.tan .tishi {
  margin: 0 auto;
  margin-top: 0.1rem;
  color: #d98181;
  font-size: 0.24rem;
  transform: scale(0.8);
  text-align: center;
}
.tan1 {
  width: 5.6rem;
  height: 3.6rem;
  background-image: url(../../assets/imgs/tan_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 3.6rem;
}
.tan1 .tan_no_txt {
  padding: 1.5rem 0.82rem 0 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  text-align: center;
  font-weight: 500;
}
.tan1 .tan_no_txt1 {
  padding: 1.3rem 0.82rem 0 0.72rem;
  font-size: 0.2rem;
  text-align: left;
  font-weight: 500;
  color: #333333;
}
.tan1 .tan_no_txt2 {
  margin-top: 0.05rem;
  color: #333333;
  font-size: 0.16rem;
  transform: scale(0.75);
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  color: #666666;
  text-align: left;
}
.tan1 .tan_no_txt2 .right {
  color: #d98181;
}
.tan1 .input {
  padding: 0 0.7rem 0 0.72rem;
  color: #333333;
  font-weight: bold;
}
.tan1 .input input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  padding: 0.04rem 0;
  font-size: 0.3rem;
}
.tan1 .tan_no_button {
  margin: 0.3rem 1.2rem 0 1.32rem;
  padding: 0.1rem 0.36rem 0.08rem 0.36rem;
  font-size: 0.28rem;
  color: #ffffff;
  background-color: #5899f6;
  text-align: center;
  border-radius: 0.16rem;
}
